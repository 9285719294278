<template>
  <tr
    class="font-raleway cursor-pointer"
    :class="selected ? 'selected' : ''"
    @click="hasMultiSelect ? onRowSelected() : actions.view && actions.view.method(exercise)"
  >
    <td v-if="hasMultiSelect"/>

    <td v-for="header in headers" :key="header.value">

      <span v-if="header.value === EXERCISE_TABLE_HEADER_NAME.value">
        {{ exercise.name ? exercise.name : exercise.id }}
      </span>

      <div v-else-if="header.value === EXERCISE_TABLE_HEADER_LANGUAGE.value">
        <font-awesome-icon
          v-for="icon in getExercisesLanguagesIcons([exercise])"
          :key="icon"
          :icon="icon"
          class="h-8 w-8"
        />
      </div>

      <div
        v-else-if="header.value ===  EXERCISE_TABLE_HEADER_CREATOR.value"
        class="flex space-x-6 items-center"
      >
        <UserAvatar :user="exercise.creator" />
        <span class="truncate">{{ exercise.creator && exercise.creator.pseudo }}</span>
      </div>

      <span v-else-if="header.value ===  EXERCISE_TABLE_HEADER_CREATED_AT.value">
        {{ formatDate(exercise.createdAt) }}
      </span>

      <span v-else-if="header.value ===  EXERCISE_TABLE_HEADER_ACCESS.value">
        {{ $t(`common.access.${exercise.access}`) }}
      </span>

      <TableActions
        v-if="header.value === EXERCISE_TABLE_HEADER_ACTIONS.value"
        :actions="actions"
        :selected="selected"
        :data="exercise"
      />

      <span v-else></span>
    </td>
  </tr>
</template>
<script>
import UserAvatar from "@/components/avatar/UserAvatar";
import {getExercisesLanguagesIcons, getLanguageIcon} from "@/constants/languages/LanguagesConstants";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TableActions from "@/components/Table/TableActions.vue";
import {findParentComponentByName} from "@/utils/FindParentComponent";
import DateUtils from "@/utils/DateUtils";
import {
  EXERCISE_TABLE_HEADER_ACCESS, EXERCISE_TABLE_HEADER_ACTIONS,
  EXERCISE_TABLE_HEADER_CREATED_AT, EXERCISE_TABLE_HEADER_CREATOR, EXERCISE_TABLE_HEADER_LANGUAGE,
  EXERCISE_TABLE_HEADER_NAME
} from "@/constants/exercices/ExercicesConstants";

export default {
  name: 'ExercisesRow',
  components: {TableActions, UserAvatar, FontAwesomeIcon},
  props: {
    exercise: {
      type: Object,
      required: true
    },
    user: {
      type: [Object, undefined]
    },
    selected: {
      type: Boolean
    },
    onRowSelected: {
      type: Function,
      required: true
    },
    actions: {
      type: Object,
      default: () => ({
        view: undefined,
        edit: undefined,
        remove: undefined
      })
    },
  },
  computed: {
    EXERCISE_TABLE_HEADER_ACTIONS() {
      return EXERCISE_TABLE_HEADER_ACTIONS;
    },
    EXERCISE_TABLE_HEADER_LANGUAGE() {
      return EXERCISE_TABLE_HEADER_LANGUAGE;
    },
    EXERCISE_TABLE_HEADER_CREATOR() {
      return EXERCISE_TABLE_HEADER_CREATOR;
    },
    EXERCISE_TABLE_HEADER_CREATED_AT() {
      return EXERCISE_TABLE_HEADER_CREATED_AT;
    },
    EXERCISE_TABLE_HEADER_ACCESS() {
      return EXERCISE_TABLE_HEADER_ACCESS;
    },
    EXERCISE_TABLE_HEADER_NAME() {
      return EXERCISE_TABLE_HEADER_NAME;
    },
    formatDate() {
      return DateUtils.formatDate;
    },
    hasMultiSelect() {
      return !!findParentComponentByName(this, 'Table')?.$props?.onSelectAll;
    },
    headers() {
      return findParentComponentByName(this, 'Table')?.$props?.headers;
    }
  },
  methods: {getExercisesLanguagesIcons, getLanguageIcon}
};
</script>
