<template>
  <div>
    <nav
      ref="navbar"
      class="px-10 md:px-24 h-24 flex justify-between md:justify-between items-center top-0 left-0 w-screen z-50"
    >
      <router-link class="geminis text-4xl" to="/">SpaceCode</router-link>
      <div class="flex gap-x-12 items-center">
        <span class="hidden md:block font-raleway-extra-bold pb-2" v-if="user && user.token && !user.is_guest">
          WELCOME {{ user.pseudo.toUpperCase() }}
        </span>
        <img
          class="cursor-pointer mb-2 md:mb:0 h-8 md:h-10"
          :data-src="require('@/assets/images/btn-moon.svg')"
          alt="Moon button"
          ref="moon"
          @click="handleMenu()"
        >
      </div>
    </nav>
    <NavbarDrawer
      v-model="showMenu"
      @close="showMenu = false"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ROUTE_LOGIN, ROUTE_PROFILE} from "@/router";
import {LOGOUT_ACTION} from "@/store/modules/userModule/actions";
import NavbarDrawer from "@/components/drawer/NavbarDrawer.vue";

export default {
  name: 'Navbar',
  components: {NavbarDrawer},
  props: {
    shouldScroll: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showMenu() {
      //if screen is mobile then
      if (window.innerWidth < 768) {
        if (this.showMenu) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }
    }
  },
  data() {
    return {
      showMenu: false,
      ROUTE_PROFILE: ROUTE_PROFILE,
      ROUTE_LOGIN: ROUTE_LOGIN
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  mounted() {
    this.shouldScroll && window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.shouldScroll && window.removeEventListener('scroll', this.handleScroll);
    document.body.style.overflow = 'auto';
  },
  beforeRouteLeave() {
    document.body.style.overflow = 'auto';
  },
  methods: {
    /**
     * @description
     * Handle the menu button click to show or hide the menu
     */
    handleMenu() {
      this.showMenu = !this.showMenu;
    },
    /**
     * @description
     * Handle the scroll event to add a class to the navbar to make it fixed or not
     */
    handleScroll() {
      if (document.body.getBoundingClientRect().y < 0) {
        this.$refs.navbar.classList.add('to-fixed');
      } else {
        this.$refs.navbar.classList.remove('to-fixed');
      }
    },
    logout() {
      this.$store.dispatch(LOGOUT_ACTION);
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.v-enter-active {
  animation: menu-animation 0.75s ease-in-out;
}

.v-leave-active {
  animation: menu-animation 0.75s ease-in-out reverse;
}

@keyframes menu-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.5s linear;
}

.to-fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  animation-name: fadeInUp;
  animation-duration: 1s;
  background-color: #090B31CC;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    top: -50px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}
</style>
