<template>
  <tbody>
    <ExercisesRow
      v-for="(exercise, index) in exercises"
      :key="index"
      :exercise="exercise"
      :selected="selectedRows.includes(exercise.id)"
      :on-row-selected="() => onRowSelected(exercise)"
      :user="user"
      :actions="actions"
    />
  </tbody>
</template>
<script>
import ExercisesRow from "@/components/exercises/ExercisesRow.vue";

export default {
  name: 'ExercisesTableBody',
  components: {ExercisesRow},
  props: {
    exercises: {
      type: Array,
      required: true
    },
    user: {
      type: (Object || undefined),
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    onRowSelected: {
      type: Function,
    },
    actions: {
      type: Object,
      default: () => ({
        view: undefined,
        edit: undefined,
        remove: undefined
      })
    }
  }
};
</script>
