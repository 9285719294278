<template>
  <div class="body min-h-screen flex flex-col" v-lazy-container="{selector: 'img'}">
    <Navbar :should-scroll="shouldNavbarScroll" ref="navbar"/>
    <div class="space-y-4 min-h-full flex-grow flex flex-col">
      <div>
        <slot name="header" />
      </div>
      <div class="flex flex-col justify-between flex-grow min-h-full" ref="content">
        <div class="sm:px-10 md:px-16 h-full flex flex-col flex-grow" :class="contentClassname">
          <slot name="content" />
        </div>
        <div class="align-bottom">
          <slot name="footer"/>
          <div class="w-full text-center mt-5 md:text-left flex flex-col items-center justify-center">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/layouts/partials/home-layout/Footer';
import Navbar from "./partials/home-layout/Navbar";

export default {
  name: 'HomeLayout',
  props: {
    contentClassname: {
      type: String,
      default: 'mx-12'
    },
    shouldNavbarScroll: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      contentTop: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.content.style.marginTop = `${this.$refs.navbar.$el.firstChild.getBoundingClientRect().height + 10}px`;
    });
  },
  components: {
    Navbar,
    Footer
  }
};
</script>
