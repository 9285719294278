<template>
  <div class="h-full px-4">
    <SideFilters
      :value="filters"
      @change="$parent.$emit('change', $event)"
      :available-filters="EXERCISES_FILTERS"
    />
  </div>
</template>
<script>
import SideFilters from "@/components/filters/SideFilters.vue";
import {EXERCISES_FILTERS} from "@/constants/exercices/ExercicesConstants";

export default {
  name: 'ExercisesFiltersContent',
  emits: ['change'],
  props:{
    filters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    EXERCISES_FILTERS() {
      return EXERCISES_FILTERS;
    }
  },
  components: {SideFilters}
};
</script>
