<template>
  <Drawer
    :drawer-style="{minWidth: minWidth + 100 + 'px'}"
    container-classname="flex items-center max-content"
    :open="value"
    @close="() => $emit('close')"
  >
    <template #content>
      <div class="flex flex-col h-full w-full mt-2 px-4 md:px-10 z-50">
        <img @click="() => $router.push(ROUTE_HOME)" :data-src="require('@/assets/images/SpaceCodeGreenLogo.png')" alt="logo" class="w-1/4 h-1/12 mx-auto mt-4 cursor-pointer">
        <div class="flex flex-col mt-10 items-center">
          <div class="flex flex-col justify-between items-center space-y-4">
            <div v-if="user && !user.is_guest" class="flex self-start space-x-4 items-center">
              <img :data-src="user && user.avatar ? user.avatar : require('@/assets/images/btn-astro.svg')" alt="astro" class="w-12 h-12 rounded-full object-cover object-center">
              <span class="text-space-green text-2xl uppercase geminis break-all w-3/4">{{user.pseudo}}</span>
            </div>
            <div v-for="(section, index) in NAVBAR_SECTIONS" class="flex flex-col space-y-6 w-full py-2" :key="index">
              <div @click="() => item.onClick ? item.onClick() : navigate(item.path)" v-for="(item, itemIndex) in section" class="flex items-center space-x-4 break-word cursor-pointer" :key="itemIndex">
                <img v-if="item.iconPath" :data-src="require('@/assets/images/btn-astro.svg')" :alt="item.name" class="w-1/10">
                <span
                  class="text-space-dark-blue font-raleway-extra-bold text-lg uppercase hover:text-space-green ease-in-out duration-100"
                  :class="$route.path === item.path ? '!text-space-green' : ''"
                >
                  {{item.name}}</span>
              </div>
              <PrimaryButton
                :text="user && !user.is_guest && user.token ? $t('common.logout').toUpperCase() : $t('common.signIn').toUpperCase()"
                class="w-3/4 self-center h-12 bg-space-dark-blue !font-green !text-white"
                @click="() => user && !user.is_guest && user.token ? $store.dispatch(LOGOUT_ACTION()) : $router.push(ROUTE_LOGIN)"
                v-if="index === 0"
              />
              <div v-if="index !== NAVBAR_SECTIONS.length - 1" class="w-full h-0.5 bg-space-green"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Drawer>
</template>
<script>
import Drawer from "@/components/drawer/Drawer.vue";
import {mapGetters} from "vuex";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import {NAVBAR_SECTIONS} from "@/constants/navbar/NavbarMenuConstants";
import {LOGOUT_ACTION} from "@/store/modules/userModule/actions";
import Router, {ROUTE_HOME, ROUTE_LOGIN} from "@/router";

export default {
  methods: {
    LOGOUT_ACTION() {
      return LOGOUT_ACTION;
    },
    navigate(path) {
      if (!path) {
        return;
      }
      Router.push(path);
      this.$emit('close');
    }
  },
  components: {PrimaryButton, Drawer},
  data() {
    return {
      minWidth: 0
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const moonButton = this.$parent.$refs.moon;
    const moonButtonRect = this.$parent.$refs.moon.getBoundingClientRect();
    this.minWidth = (window.innerWidth - moonButtonRect.right ) + moonButton.clientWidth +  20;
  },
  computed: {
    ROUTE_HOME() {
      return ROUTE_HOME;
    },
    ROUTE_LOGIN() {
      return ROUTE_LOGIN;
    },
    ...mapGetters({
      user: 'getUser',
    }),
    NAVBAR_SECTIONS() {
      return NAVBAR_SECTIONS(this.user);
    }
  }
};
</script>
