<template>
  <div
    v-if="Object.values(actions).some(action => !!action.method)"
    class="self-end flex space-x-3 items-center justify-center items-center"
  >
    <IconButton
      v-if="actions && actions.view && actions.view.method"
      icon="eye"
      :icon-classname="selected ? '!text-space-dark-blue' : ''"
      :classname="`${selected ? '!bg-white' : '!bg-space-dark-blue'}`"
      :tooltip-text="actions.view.label"
      @click="actions.view.method(data)"
    />
    <IconButton
      v-if="actions && actions.edit && actions.edit.method"
      icon="edit"
      :icon-classname="selected ? '!text-space-dark-blue' : ''"
      :classname="`${selected ? '!bg-white !text-space-dark-blue' : '!bg-space-dark-blue text-white'}`"
      :tooltip-text="actions.edit.label"
      @click="actions.edit.method(data)"
    />
    <IconButtonDelete
      v-if="actions && actions.remove && actions.remove.method"
      :tooltip-text="actions.remove.label"
      @click="actions.remove.method(data)"
    />
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconButtonDelete from "@/components/buttons/IconButtonDelete.vue";

export default {
  name: 'TableActions',
  components: {IconButtonDelete, IconButton},
  props: {
    actions: {
      type: Object,
      default: () => ({
        edit: undefined,
        view: undefined,
        remove: undefined
      })
    },
    selected: {
      type: Boolean
    },
    data: {
      type: Object
    }
  }
};
</script>
