import i18n from "@/i18n/i18n";
import {getLanguageIcon} from "@/constants/languages/LanguagesConstants";
import {EXERCISE_TYPE_ERROR} from "@/constants/exercices/ExercicesConstants";

export const BUNDLE_TABLE_HEADER_NAME = {
  name: i18n.t('bundles.name'),
  value: 'name',
};

export const BUNDLE_TABLE_HEADER_EXERCISES_COUNT = {
  name: i18n.t('bundles.exercises'),
  value: 'exercises_count',
};

export const BUNDLE_TABLE_HEADER_LANGUAGE = {
  name: i18n.t('bundles.languages'),
  value: 'language',
};

export const BUNDLE_TABLE_HEADER_ACCESS = {
  name: i18n.t('bundles.access'),
  value: 'access',
};

export const BUNDLE_TABLE_HEADER_CREATOR = {
  name: i18n.t('bundles.creator'),
  value: 'creator',
};

export const BUNDLE_TABLE_HEADER_CREATED_AT = {
  name: i18n.t('bundles.created_at'),
  value: 'created_at',
};

export const BUNDLE_TABLE_HEADER_ACTIONS = {
  name: i18n.t('common.table.actions'),
  value: 'actions'
};

export const BUNDLES_TABLE_HEADER = (isCommunity) => [
  BUNDLE_TABLE_HEADER_NAME,
  BUNDLE_TABLE_HEADER_EXERCISES_COUNT,
  BUNDLE_TABLE_HEADER_LANGUAGE,
  BUNDLE_TABLE_HEADER_CREATOR,
  BUNDLE_TABLE_HEADER_CREATED_AT,

  ...(isCommunity ? [] : [
    BUNDLE_TABLE_HEADER_ACCESS,
  ]),
  {
    ...BUNDLE_TABLE_HEADER_ACTIONS,
    classname: isCommunity ? 'w-1/10' : 'w-1/10'
  }
];

export const BUNDLES_FILTERS = [
  {
    name: i18n.t('bundles.filters.exercises.languages'),
    field: 'exercise.language',
    isArray: true,
    operator: 'in',
    options: [
      {
        name: 'javascript',
        icon: getLanguageIcon('javascript'),
        value: 'javascript',
      },
      {
        name: 'PHP',
        icon: getLanguageIcon('php'),
        value: 'php',
      },
      {
        name: 'Python',
        icon: getLanguageIcon('python'),
        value: 'python',
      },
    ]
  },
  {
    isArray: true,
    operator: 'in',
    name: i18n.t('bundles.filters.exercises.types'),
    field: 'exercise.mode',
    options: [
      EXERCISE_TYPE_ERROR
    ]
  }
];
