<template>
  <div
    class="wrapper"
    :class="classname"
  >
    <div
      ref="taebSwitch"
      class="taeb-switch right text-center edge-right"
      :style="switchStyle"
    >
      <div
        v-for="(tab, index) in value"
        :key="index"
        ref="tab"
        class="taeb"
        :class="`${tab.active ? 'active' : ''}`"
        @click="() => handleTabSwitch(index)"
      >
        {{ tab.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RoundedTabs",
  props: {
    value: Array,
    classname: String,
  },
  emits: ['changed'],
  data(){
    return {
      // to edit css styles via JS
      switchStyle: {
        '--overlay-width': `${this.value.length > 0 ? 100 / this.value.length : 100 }%`,
        '--overlay-left': `${this.value.length > 0 ? this.value.findIndex(tab => tab.active) > 0 ? 100 / this.value.findIndex(tab => tab.active) : 0 : 100 }%`
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.switchStyle = {
        '--overlay-width': `${this.value.length > 0 ? 100 / this.value.length : 100 }%`,
        '--overlay-left': `${this.value.length > 0 ? this.value.findIndex(tab => tab.active) > 0 ? (100 / this.value.length) / this.value.findIndex(tab => tab.active) : 0 : 100 }%`
      };
    });
  },
  methods: {
    //handle tab switch change with background and text transition
    handleTabSwitch(tabToActivateIndex) {
      const activeTabIndex = this.value.findIndex((tab) => tab.active);

      if (tabToActivateIndex === activeTabIndex) {
        return;
      }

      this.switchStyle["--overlay-left"] = `${100 / (this.value.length / (tabToActivateIndex))}%`;
      this.$refs.taebSwitch.classList.remove('edge-left', 'edge-right');
      if (tabToActivateIndex === 0) {
        this.$refs.taebSwitch.classList.add('edge-left');
      } else if (tabToActivateIndex === this.value.length - 1) {
        this.$refs.taebSwitch.classList.add('edge-right');
      }

      const updatedTabs = [...this.value];

      updatedTabs[activeTabIndex].active = false;
      updatedTabs[tabToActivateIndex].active = true;

      this.$emit('changed', updatedTabs);
    },
  }
};
</script>
<style scoped>
.taeb-switch {
  @apply flex justify-between items-center relative;
}

.taeb-switch:after {
  @apply absolute h-1 bg-space-green top-0 rounded-full h-full z-0;
  content: "";
  width: var(--overlay-width);
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
}

.taeb-switch.edge-left:after, .taeb-switch.edge-right:after {
  transition: left 0.2s cubic-bezier(0.1, 0.3, 0.32, 1);
}

.taeb-switch.right:after {
  left: var(--overlay-left);
}

.taeb-switch .taeb {
  @apply block flex flex-1 p-4 z-10 relative cursor-pointer text-lg font-bold h-12 justify-center items-center text-space-dark-blue;
  transition: color 100ms;
  line-height: normal;
  user-select: none;
}

.taeb-switch .taeb.active {
  @apply text-white;
}

.wrapper {
  @apply rounded-full w-full h-12 bg-white;
}

</style>
