<template>
  <div
    class="go-back-container flex flex-nowrap items-center cursor-pointer space-x-1/2"
    :class="containerClassname"
    @click="goBack"
  >
    <slot name="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </slot>
    <span
      :class="textClassname"
      class="text-white font-raleway-extra-bold"
    >
      {{ text ? text : $t('common.go_back') }}
    </span>
  </div>
</template>
<script>
import i18n from "@/i18n/i18n";
import {ROUTE_HOME} from "@/router";

export default {
  name: "GoBackButton",
  props: {
    to: {
      type: String,
      default: ROUTE_HOME.path
    },
    onClick: {
      type: Function
    },
    text: {
      type: String,
      default: i18n.t('common.go_back')
    },
    textClassname: String,
    containerClassname: String
  },
  methods: {
    goBack() {
      this.onClick ? this.onClick() : this.$router.push(this.to);
    },
  },
};
</script>
<style scoped>
.go-back-container{
  width: fit-content;
}
</style>
