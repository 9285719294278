<template>
  <tr
    class="font-raleway cursor-pointer"
    :class="selected ? 'selected' : ''"
    @click="onRowSelected"
  >
    <td v-if="hasMultiSelect" />
    <td v-for="header in headers" :key="header.value">
      <span v-if="header.value === BUNDLE_TABLE_HEADER_NAME.value">{{ bundle.name }}</span>
      <span v-else-if="header.value === BUNDLE_TABLE_HEADER_EXERCISES_COUNT.value">
        {{ bundle.exercises ? bundle.exercises.length : 0 }}
      </span>
      <div v-else-if="header.value === BUNDLE_TABLE_HEADER_LANGUAGE.value">
        <font-awesome-icon
          v-for="icon in getExercisesLanguagesIcons(bundle.exercises)"
          :key="icon"
          :icon="icon"
          class="h-8 w-8"
        />
      </div>
      <div
        class="flex space-x-6 items-center"
        v-if="header.value === BUNDLE_TABLE_HEADER_CREATOR.value"
      >
        <UserAvatar :user="bundle.creator" />
        <span>{{ bundle.creator && bundle.creator.pseudo }}</span>
      </div>
      <span v-if="header.value === BUNDLE_TABLE_HEADER_CREATED_AT.value">
        {{ formatDate(bundle.createdAt) }}
      </span>
      <span v-if="header.value === BUNDLE_TABLE_HEADER_ACCESS.value">
        {{ $t(`common.access.${bundle.access}`) }}
      </span>
      <TableActions
        v-if="header.value === BUNDLE_TABLE_HEADER_ACTIONS.value"
        :actions="actions"
        :selected="selected"
        :data="bundle"
      />
    </td>
  </tr>
</template>
<script>
import UserAvatar from "@/components/avatar/UserAvatar";
import {getExercisesLanguagesIcons} from "@/constants/languages/LanguagesConstants";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TableActions from "@/components/Table/TableActions.vue";
import DateUtils from "@/utils/DateUtils";
import {findParentComponentByName} from "@/utils/FindParentComponent";
import {
  BUNDLE_TABLE_HEADER_ACCESS, BUNDLE_TABLE_HEADER_ACTIONS,
  BUNDLE_TABLE_HEADER_CREATED_AT,
  BUNDLE_TABLE_HEADER_CREATOR,
  BUNDLE_TABLE_HEADER_EXERCISES_COUNT,
  BUNDLE_TABLE_HEADER_LANGUAGE,
  BUNDLE_TABLE_HEADER_NAME
} from "@/constants/bundles/BundlesConstants";

export default {
  name: 'BundlesRow',
  components: {TableActions, UserAvatar, FontAwesomeIcon},
  props: {
    bundle: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean
    },
    onRowSelected: {
      type: Function,
      required: true
    },
    actions: {
      type: Object,
      default: () => ({
        view: undefined,
        edit: undefined,
        remove: undefined
      })
    }
  },
  computed: {
    BUNDLE_TABLE_HEADER_ACTIONS() {
      return BUNDLE_TABLE_HEADER_ACTIONS;
    },
    BUNDLE_TABLE_HEADER_ACCESS() {
      return BUNDLE_TABLE_HEADER_ACCESS;
    },
    BUNDLE_TABLE_HEADER_CREATED_AT() {
      return BUNDLE_TABLE_HEADER_CREATED_AT;
    },
    BUNDLE_TABLE_HEADER_CREATOR() {
      return BUNDLE_TABLE_HEADER_CREATOR;
    },
    BUNDLE_TABLE_HEADER_LANGUAGE() {
      return BUNDLE_TABLE_HEADER_LANGUAGE;
    },
    BUNDLE_TABLE_HEADER_EXERCISES_COUNT() {
      return BUNDLE_TABLE_HEADER_EXERCISES_COUNT;
    },
    BUNDLE_TABLE_HEADER_NAME() {
      return BUNDLE_TABLE_HEADER_NAME;
    },
    formatDate() {
      return DateUtils.formatDate;
    },
    headers(){
      return findParentComponentByName(this, 'Table')?.$props?.headers;
    },
    hasMultiSelect() {
      return !!findParentComponentByName(this, 'Table')?.$props?.onSelectAll;
    },
  },
  data() {
    return {};
  },
  methods: {getExercisesLanguagesIcons},
};
</script>
<style scoped>
</style>
