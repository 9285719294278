import i18n from "@/i18n/i18n";
import {API} from "@/utils/api";
import I18n from "@/i18n/i18n";
import {TOAST_DEFAULT} from "@/constants/toast/toastConstants";

class BundleService{

  /**
   * Create bundle
   * @param bundle
   * @return {Promise<T>}
   */
  createBundle(bundle) {
    return API.post('/bundles', bundle)
      .then(response => response.data)
      .catch(() => {
        this.$toast.error(I18n.t('bundle.form.errors.create'), TOAST_DEFAULT);
      });
  }

  /**
   * Get bundle
   * @param bundleId
   * @return {Promise<T>}
   */
  getBundle(bundleId) {
    return API.get(`/bundles/${bundleId}`)
      .then(response => response.data)
      .catch(() => {
        this.$toast.error(I18n.t('bundle.form.errors.get'), TOAST_DEFAULT);
      });
  }

  /**
   * Update bundle
   * @param bundle
   * @return {Promise<T>}
   */
  updateBundle(bundle) {
    return API.put(`/bundles/${bundle.id}`, bundle)
      .then(response => response.data)
      .catch(() => {
        this.$toast.error(I18n.t('bundle.form.errors.update'), TOAST_DEFAULT);
      });
  }

  /**
     * Search bundles
     * @param params
     */
  search(params) {
    return API.get('/bundles', {params: params})
      .then(response => response.data);
  }
  validateBundleForm(bundle) {
    return {
      name: bundle.name ? null : i18n.t('common.errors.required_field'),
      exercises: bundle.exercises && bundle.exercises.length > 0 ? null : i18n.t('exercises.errors.add_at_least_one_exercise'),
    };
  }
}
export default new BundleService();
