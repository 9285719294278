<template>
  <HomeLayout content-classname="">
    <template #content>
      <div class="px-6 h-full">
        <div class="p-8 exercices-container bg-space-dark-blue">
          <div class="flex items-center justify-center flex-col mb-16">
            <h1 class="geminis text-space-green text-4xl">
              {{ $t(`exercises.${isCommunity ? 'community' : 'my_exercices_and_bundles'}.title`) }}
            </h1>
            <p class="max-w-screen-sm	text-center">
              {{ $t(`exercises.${isCommunity ? 'community' : 'my_exercices_and_bundles'}.subtitle`) }}
            </p>
          </div>
          <Table
            :headers="headers"
            :pagination-values="paginationValues"
            table-container-classname="mt-3 bg-white"
            :data="tableData"
            :selected-rows="selectedRows"
            :on-select-all="!isCommunity ? undefined : undefined"
            :is-loading="loading"
            :actions="tableActions"
            @scroll-end="handleScrollEnd"
          >
            <template #title>
              <RoundedTabs
                :value="tabs"
                @changed="handleTabsChanged"
                classname="!w-4/5 sm:!w-3/5 lg:!w-1/3 xl:!w-1/4"
              />
            </template>
            <template #beforeActions>
              <div class="flex space-x-2 h-8">
                <SearchBar
                  :value="search"
                  @input="handleSearch"
                  container-classname="h-8 !bg-white"
                  input-wrapper-classname="h-8 !bg-white"
                  input-classname="text-space-dark-blue"
                  search-icon-classname="!text-space-dark-blue"
                  clear-icon-classname="!text-space-dark-blue"
                />
                <PrimaryButton
                  bg-color="bg-white"
                  classes="!text-space-dark-blue h-full py-0"
                  :text="$t('common.filter')"
                  @click="openFilters = !openFilters"
                />
              </div>

            </template>
            <template #body v-if="tableData.length">
              <ExercisesTableBody
                v-if="tabs.some(tab => tab.active) && tabs.find(tab => tab.active).value === 'exercises' && tableData.length"
                :exercises="tableData"
                :user="user"
                :on-row-selected="onRowSelected"
                :selected-rows="selectedRows"
                :actions="actions"
              />

              <BundlesTableBody
                v-if="tabs.some(tab => tab.active) && tabs.find(tab => tab.active).value === 'bundles' && tableData.length"
                :bundles="tableData"
                :user="user"
                :on-row-selected="onRowSelected"
                :selected-rows="selectedRows"
                :actions="actions"
              />
            </template>
          </Table>
        </div>
        <Modal
          v-if="!!dataToShowInModal"
          :on-close="() => dataToShowInModal = null"
          :value="!!dataToShowInModal"
        >
          <template #content>
            <BundleDetailsModal
              v-if="tabs && tabs[0].active"
              :bundle="dataToShowInModal"
              :user="user"
            />

            <ExerciseDetailsModal
              v-if="tabs && tabs[1].active"
              :exercise="dataToShowInModal"
            />
          </template>
        </Modal>
      </div>
      <Drawer :open="openFilters"
              @close="handleDrawerClose"
              @change="handleFiltersChanged"
      >
        <template #content>
          <BundlesFiltersContent
            v-if="tabs[0].active"
            :filters="filters"
            @change="filters = $event"
          />
          <ExercisesFiltersContent
            v-if="tabs[1].active"
            :filters="filters"
            @change="filters = $event"
          />
        </template>
      </Drawer>
    </template>
  </HomeLayout>

</template>
<script>
import RoundedTabs from "@/components/tabs/RoundedTabs.vue";
import SearchBar from "@/components/inputs/SearchBar.vue";
import Table from "@/components/Table/Table.vue";
import {EXERCISES_BUNDLES_TABS, EXERCISES_TABLE_HEADER} from "@/constants/exercices/ExercicesConstants";
import ExercisesTableBody from "@/components/exercises/ExercisesTableBody.vue";
import {mapGetters} from "vuex";
import BundlesTableBody from "@/components/bundles/BundlesTableBody.vue";
import {BUNDLES_TABLE_HEADER} from "@/constants/bundles/BundlesConstants";
import ExerciseDetailsModal from "@/components/modals/exerciseDetailsModal.vue";
import Modal from "@/components/modals/DefaultModal.vue";
import HomeLayout from "@/layouts/HomeLayout.vue";
import {ROUTE_CREATE_BUNDLE, ROUTE_UPDATE_BUNDLE, ROUTE_UPDATE_EXERCISE} from "@/router";
import BundleDetailsModal from "@/components/modals/bundleDetailsModal.vue";
import {debounce} from "vue-debounce";
import i18n from "@/i18n/i18n";
import BundleService from "@/services/bundles/BundleService";
import ExercisesService from "@/services/ExercisesService";
import {createQueryParamString} from "@/utils/requests/FilterQueryParamGenerator";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import Drawer from "@/components/drawer/Drawer.vue";
import ExercisesFiltersContent from "@/components/filters/exercises/ExercisesFilterContent.vue";
import BundlesFiltersContent from "@/components/filters/bundles/BundlesFilterContent.vue";

export default {
  name: 'ExercisesBundles',
  components: {
    BundlesFiltersContent,
    ExercisesFiltersContent,
    Drawer,
    PrimaryButton,
    BundleDetailsModal,
    HomeLayout,
    Modal,
    ExerciseDetailsModal, BundlesTableBody, ExercisesTableBody, Table, SearchBar, RoundedTabs
  },
  props: {
    isCommunity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      tabs: this.getDefaultTabs(),
      headers: this.getDefaultHeaders(),
      loading: true,
      tableData: [],
      selectedRows: [],
      dataToShowInModal: undefined,
      paginationValues: {
        page: 1,
        pageSize: 10
      },
      filters: [],
      openFilters: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    tableActions() {
      return !this.user || this.user.is_guest ? {} : {
        add: {
          method: this.handleAdd,
          label: i18n.t(this.tabs[1].active ? 'exercises.actions.add' : 'bundles.actions.add')
        },
        //TODO
        // remove: !this.isCommunity && this.selectedRows.length && {
        //   method: this.handleDelete,
        //   label: i18n.t(this.tabs[1].active ? 'exercises.actions.delete_many' : 'bundles.actions.delete_many')
        // }
      };
    },
    actions() {
      return {
        view: {
          label: this.tabs[1].active ? i18n.t('exercises.actions.view') : i18n.t('bundles.actions.view'),
          method: this.handleViewDetails
        },
        ...(this.isCommunity ? {} : {
          edit: {
            label: this.tabs[1].active ? i18n.t('exercises.actions.edit') : i18n.t('bundles.actions.edit'),
            method: (data) => this.$router.push({
              name: (this.tabs[1].active ? ROUTE_UPDATE_EXERCISE : ROUTE_UPDATE_BUNDLE).name,
              params: {id: data.id}
            })
          },
        })
        // TODO

        // remove: {
        //   label: this.tabs[1].active ? i18n.t('exercises.actions.delete') : i18n.t('bundles.actions.delete'),
        //   method: !this.isCommunity && this.handleDelete,
        // }
      };
    },
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    // we cannot use debounce on default watch prop because of interval used by debounce
    this.handleTabsChanged(this.tabs);
    this.unwatch = this.$watch('search', debounce(() => {
      this.searchData();
    }, 500));
  },
  beforeRouteLeave(to, from, next) {
    this.selectedRows = [];
    this.tableData = [];
    this.tabs = [...EXERCISES_BUNDLES_TABS];
    next();
  },
  methods: {
    handleSearch(search) {
      this.paginationValues = {
        page: 1,
        pageSize: 10
      };
      this.search = search;
    },
    handleFiltersChanged(filters) {
      this.handleDrawerClose();
      this.filters = filters;

      debounce(() => {
        this.paginationValues = {
          page: 1,
          pageSize: 10
        };
        this.searchData();
      }, 500)();
    },
    handleDrawerClose() {
      this.openFilters = false;
    },
    handleScrollEnd() {
      if (this.paginationValues.page < this.paginationValues.totalPages) {
        this.searchData(this.paginationValues.page + 1);
      }
    },
    handleTabsChanged(tabs) {
      this.paginationValues = {
        page: 1,
        pageSize: 10
      };
      this.headers = tabs[1].active ? EXERCISES_TABLE_HEADER(this.isCommunity) : BUNDLES_TABLE_HEADER(this.isCommunity);
      this.search = '';
      this.filters = [];
      if (this.search !== '') {
        this.search = '';
        return;
      }
      this.searchData();
      this.tabs = tabs;
    },
    handleViewDetails(data) {
      this.dataToShowInModal = data;
    },
    handleAdd() {
      if (this.tabs[1].active) {
        this.$router.push({name: 'createExercise'});
      } else {
        this.$router.push(ROUTE_CREATE_BUNDLE.path);
      }
    },
    getDefaultTabs() {
      const tabParam = this.$route.query.tab;

      return [...(tabParam ? EXERCISES_BUNDLES_TABS.map(tab => ({
        ...tab,
        active: tabParam === tab.value
      }
      )) : EXERCISES_BUNDLES_TABS)];
    },
    getDefaultHeaders() {
      const tabParam = this.$route.query.tab;
      return tabParam === 'exercises' ? EXERCISES_TABLE_HEADER(this.isCommunity) : BUNDLES_TABLE_HEADER(this.isCommunity);
    },
    onRowSelected(data) {
      //todo enable selection when table actions are implemented

      this.dataToShowInModal = data;
      // if (this.isCommunity) {
      //   this.dataToShowInModal = this.tableData[rowIndex]
      //   return
      // }
      // if (!this.user || !this.tableData[rowIndex].creator || this.user.id !== this.tableData[rowIndex].creator.id) {
      //   return
      // }
      // this.selectedRows = this.selectedRows.some(index => index === rowIndex) ? this.selectedRows.filter(index => index !== rowIndex) : [...this.selectedRows, rowIndex]
    },
    handleSelectAll() {
      this.selectedRows = this.selectedRows.length === this.tableData.length ? [] : this.tableData.map((_, index) => index);
    },
    handleDelete() {
      //todo delete
    },
    searchData(page) {
      if (!page){
        this.loading = true;
        this.selectedRows = [];
      }

      const serviceFunction = this.tabs[1].active ? ExercisesService.search : BundleService.search;
      serviceFunction({
        ...this.paginationValues,
        page: page || this.paginationValues.page,
        filters: createQueryParamString([
          ...this.filters,
          ...(this.search ? [{field: this.tabs[0]?.active ? 'bundle.name' : 'exercise.name', value: this.search, operator: 'iLike'}] : []),
          ...(!this.isCommunity ? [{
            value: this.user.id,
            operator: 'eq',
            field: this.tabs[0].active ? 'bundle.creator.id' : 'exercise.creator.id'
          }] : [])
        ])
      })
        .then((searchResponse) => {
          this.tableData = [...(page ? [...this.tableData] : []), ...searchResponse.results];
          this.paginationValues = {
            ...this.paginationValues,
            total: searchResponse.total,
            page: searchResponse.page,
            totalPages: searchResponse.totalPages,
            totalItemsInPage: searchResponse.totalItemsInPage
          };
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.exercices-container {
  @apply border border-space-green rounded-xl h-full w-full;
  border-width: 2px;
}

>>> .table-container {
  @apply mx-0;
  max-height: 50vh;
}

>>> th {
  @apply py-6;
}

>>> .modal-content {
  @apply block;
  max-height: 80vh;
}
</style>

