import {API} from "@/utils/api";
import i18n from "@/i18n/i18n";

class ExercisesService {

  /**
   * Create exercise
   * @param exercise
   * @return {Promise<T>}
   */
  createExercise(exercise) {
    return API.post('/exercises', exercise)
      .then((response) => response.data)
      .catch(() => {
        this.$toast.error(`exercises.errors.${exercise?.id ? 'update': 'create'}`);
      });
  }

  /**
   * Get exercise
   * @param exerciseId
   * @return {Promise<T>}
   */
  getExercise(exerciseId) {
    return API.get(`/exercises/${exerciseId}`)
      .then((response) => response.data)
      .catch(() => {
        this.$toast.error(`exercises.errors.get`);
      });
  }

  /**
   * Update exercise
   * @param exercise
   * @return {Promise<T>}
   */
  updateExercise(exercise) {
    return API.put(`/exercises/${exercise.id}`, exercise)
      .then((response) => response.data)
      .catch(() => {
        this.$toast.error(`exercises.errors.${exercise?.id ? 'update': 'create'}`);
      });
  }

  /**
   * Test exercise creation
   * @param exerciseId
   * @param code
   * @return {Promise<T>}
   */
  testExerciseCreation(exerciseId, code) {
    return API.post(`/exercises/${exerciseId}/test`, {code})
      .then((response) => response.data)
      .catch(() => {
        this.$toast.error(`exercises.errors.test`);
      });
  }

  /**
   * Search exercises
   * @param params
   * @return {Promise<T>}
   */
  search(params) {
    return API.get('/exercises', {
      params
    })
      .then((response) => response.data);
  }

  /**
   * Validate exercise form
   * @param exercise
   * @return {{code: (VueI18n.TranslateResult|null), instruction: (VueI18n.TranslateResult|string|null), name: (VueI18n.TranslateResult|string|null), language: (VueI18n.TranslateResult|null)}}
   */
  validateExerciseForm(exercise) {
    return {
      instruction: !exercise.instruction ? i18n.t('common.errors.required_field') : exercise.instruction.length > 260 ? `${i18n.t('common.max_characters')} 260` : null,
      name: !exercise.name ? i18n.t('common.errors.required_field') : exercise.name.length > 50 ? `${i18n.t('common.max_characters')} 50` : null,
      language: !exercise.language ? i18n.t('common.errors.required_field') : null,
      code: !exercise.code ? i18n.t('common.errors.required_field') : null,
    };
  }
}

export default new ExercisesService();
