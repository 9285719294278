<template>
  <div class="bg-space-dark-blue text-[10px] md:text-xs px-1 lg:w-4/5 xl:w-3/5 flex space-x-1 space-between justify-center items-center self-center flex-col md:flex-row">
    <router-link
      class="text-white no-underline font-bold mb-2"
      to="/terms"
    >
      {{ $t('footer.legal').toUpperCase() }}
    </router-link>
    <span class="mb-2 hidden md:block"> | </span>
    <router-link
      class="text-white no-underline font-bold mb-2"
      to="/terms#condition"
    >{{ $t('footer.terms').toUpperCase() }}</router-link>
    <span class="mb-2 hidden md:block"> | </span>
    <router-link
      class="text-white no-underline font-bold mb-2"
      :to="{ name: 'Contact' }"
    >
      {{$t('footer.problem').toUpperCase()}}
    </router-link>
    <span class="mb-2 hidden md:block"> | </span>
    <button
      v-if="true"
      class="z-50 text-lg mb-2"
      @click="handleCookieClick"
    >
      🍪
    </button>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    canShowCookieButton() {
      return window.cc.get('spacecode_cookie') && !document.querySelector('[data-cc="c-settings"]');
    }
  },
  methods: {
    handleCookieClick() {
      window.cc.showSettings();
    }
  },
};
</script>

<style scoped>
</style>
