<template>
  <div class="flex flex-col space-y-3 justify-center">
    <div
      v-if="internalFilters && Object.keys(internalFilters).length > 0"
      class="flex flex-wrap gap-y-2 gap-x-1 items-center">
      <template v-for="filter in internalFilters">
        <Chip
          v-for="(value, index) in Array.isArray(filter.value) ? filter.value : [filter.value]"
          :key="`${filter.field}-${index}`"
          :label="availableFilters.find(availableFilter => availableFilter.field === filter.field).options.find(option => option.value === value).name"
          @click="() => handleFilterChange(filter, value)"
        />
      </template>
    </div>
    <div class="flex flex-col space-y-4">
      <div
        v-for="filter in availableFilters"
        :key="filter.field"
        class="flex flex-col"
      >
        <span
          class="text-space-dark-blue font-bold mb-2"
        >{{ filter.name }}</span>
        <FilterCheckbox
          v-for="(option, index) in filter.options"
          :value="internalFilters && internalFilters.find(internalFilter => internalFilter.field === filter.field) && internalFilters.find(internalFilter => internalFilter.field === filter.field).value.includes(option.value)"
          :option="option"
          :key="index"
          @click="() => handleFilterChange(filter, option.value)"
          class="text-space-dark-blue w-full"
        />
      </div>

    </div>
    <span
      v-if="Object.values(internalFilters).flat().length > 0"
      class="font-bold text-space-dark-blue cursor-pointer underline text-center"
      @click="() => internalFilters = []">{{ $t('common.reset') }}</span>
    <PrimaryButton
      :text="$t('common.search')"
      @click="($emit('change', internalFilters))"
      bg-color="bg-space-dark-blue"
      classes="py-0 h-10"
    />
  </div>
</template>
<script>
import Chip from "@/components/chips/Chip.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import FilterCheckbox from "@/components/filters/FilterCheckbox.vue";

export default {
  name: 'SideFilters',
  emits: ['change', 'close'],
  components: {FilterCheckbox, PrimaryButton, Chip},
  props: {
    availableFilters: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      internalFilters: this.value
    };
  },
  watch: {
    value(filters) {
      this.internalFilters = filters;
    }
  },
  methods: {
    handleFilterChange(filter, value) {
      let newFilters = [...this.internalFilters.filter(existingFilter => existingFilter.field !== filter.field),];
      const existingFilter = this.internalFilters.find(existingFilter => existingFilter?.field === filter.field);

      if (!!existingFilter && (Array.isArray(existingFilter.value) ? existingFilter.value.includes(value) : existingFilter.value === value)) {
        newFilters = [
          ...newFilters,
          {
            field: filter.field,
            operator: filter.operator,
            value: Array.isArray(existingFilter.value) ? existingFilter.value.filter((item) => item !== value) : undefined
          }
        ];
      } else {
        newFilters = [
          ...newFilters,
          {
            field: filter.field,
            operator: filter.operator,
            value: this.availableFilters.find(availableFilter => availableFilter.field === filter.field)?.isArray ? [...(existingFilter?.value ? [...existingFilter.value] : []), value] : value
          }
        ];
      }
      this.internalFilters = [...newFilters];
    },
  }
};
</script>
