<template>
  <div class="bg-white flex flex-col space-y-4">
    <div class="flex flex-col">
      <span class="text-space-dark-blue font-bold text-2xl">{{$t('exercises.language')}}</span>
      <font-awesome-icon
        class="text-space-green h-10 w-10"
        :icon="getLanguageIcon(exercise.language)"
      />
    </div>
    <div class="flex flex-col space-y-2">
      <span class="text-space-dark-blue font-bold text-2xl">{{$t('exercises.instruction')}}</span>
      <p class="text-white bg-space-dark-blue rounded-xl py-4 px-5">
        {{ exercise.instruction }}
      </p>
    </div>
    <div class="flex flex-col space-y-2">
      <span class="text-space-dark-blue font-bold text-2xl">{{$t('exercises.required_codes')}}</span>
      <HelpInfo :help="$t('exercises.help_required_codes')" />
      <ul
        v-if="exercise.requiredCodes && exercise.requiredCodes.length > 0"
        class="list-none space-y-2"
      >
        <li
          v-for="(requiredCode, index) in exercise.requiredCodes"
          :key="index"
          class="bg-space-dark-blue rounded-full py-2 px-4 text-white w-fit-content"
        >
          {{ requiredCode.code }}
        </li>
      </ul>
    </div>
    <div class="flex flex-col space-y-2">
      <span class="text-space-dark-blue font-bold text-2xl">{{$t('exercises.expected_result')}}</span>
      <TextFormInput
        :disabled="true"
        wrapper-classname="w-fit-content shadow-none"
        :value="exercise.expected_result"
      />
    </div>
    <span class="text-space-dark-blue font-bold text-2xl">{{$t('exercises.initial_code')}}</span>
    <div class="flex flex-col space-y-2">
      <div
        ref="editor"
        class="h-80 w-full overflow-y-hidden min-w-100"
      />
    </div>
  </div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import HelpInfo from "@/components/help/HelpInfo.vue";
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import loader from "@monaco-editor/loader";
import {getLanguageIcon} from "@/constants/languages/LanguagesConstants";

export default {
  name: 'ExerciseDetailsModal',
  components:{TextFormInput, HelpInfo, FontAwesomeIcon},
  props: {
    exercise: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    const monaco = await loader.init();
    this.userEditor = monaco.editor.create(this.$refs.editor, {
      value: this.exercise.code,
      language: this.exercise.language,
      theme: 'vs-dark',
      readOnly: true,
      minimap: {enabled: false},
      automaticLayout: true,
    });
  },
  methods: {getLanguageIcon}
};
</script>
