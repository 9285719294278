<template>
  <div
    class="flex bg-white border-space-dark-blue pl-3 pr-1 py-0.5 space-x-2 border items-center rounded-full cursor-pointer"
    @click="handleClick"
  >
    <span class="text-space-dark-blue font-bold">{{ label }}</span>
    <font-awesome-icon
      icon="fa-solid fa-circle-xmark"
      class="text-space-dark-blue"
    />
  </div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'Chip',
  emits: ['click'],
  components: {FontAwesomeIcon},
  props:{
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>
