import { render, staticRenderFns } from "./RoundedTabs.vue?vue&type=template&id=46ba07c2&scoped=true&"
import script from "./RoundedTabs.vue?vue&type=script&lang=js&"
export * from "./RoundedTabs.vue?vue&type=script&lang=js&"
import style0 from "./RoundedTabs.vue?vue&type=style&index=0&id=46ba07c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ba07c2",
  null
  
)

export default component.exports