<template>
  <div
    class="flex justify-between rounded-full bg-space-dark-blue"
    :class="containerClassname"
  >
    <TextFormInput
      :value="value"
      :wrapper-classname="'!py-0 shadow-none ' + inputWrapperClassname"
      :placeholder="placeholder"
      :input-classname="inputClassname"
      container-classname="w-full"
      @input="updateValue"
    />
    <div class="flex items-center pr-6 space-x-2">
      <font-awesome-icon
        icon="close"
        class="text-white cursor-pointer"
        :class="`${value && value.length ? '' : 'invisible'} ${clearIconClassname}`"
        @click="updateValue('')"
      />
      <font-awesome-icon
        :icon="loading ? 'circle-notch':'search'"
        class="text-white cursor-pointer"
        :class="loading ? 'animate-spin cursor-wait' : '' + ' ' + searchIconClassname"
        @click="handleSearchClick"
      />
    </div>
  </div>
</template>
<script>
import TextFormInput from "@/components/inputs/TextFormInput";
import i18n from "@/i18n/i18n";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SearchBar",
  components: {TextFormInput, FontAwesomeIcon},
  props: {
    containerClassname: {
      type: String,
      default: ""
    },
    inputWrapperClassname: {
      type: String,
      default: ""
    },
    inputClassname: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: i18n.t('search_bar.placeholder')
    },
    searchIconClassname: {
      type: String,
      default: ""
    },
    clearIconClassname: {
      type: String,
      default: ""
    },
    handleSearch: {
      type: Function
    },
    overrideLoading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },
  emits: ['input'],
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    overrideLoading: function (value) {
      this.loading = value;
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    async handleSearchClick() {
      //5s promise
      if (this.handleSearch && !this.loading) {
        this.loading = true;
        await this.handleSearch();
        this.loading = false;
      }
    }
  }
};
</script>
