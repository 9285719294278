<template>
  <div
    class="px-4 flex items-center cursor-pointer w-fit-content"
    @click="() => $emit('click')"
  >
    <PrimaryCheckbox
      :value="value"
      class="mr-2"
      @input="() => $emit('click')"
    />
    <font-awesome-icon
      v-if="option.icon"
      :icon="option.icon"
      class="text-space-dark-blue mr-2 h-6 w-6"
    />
    <span>{{ option.name }}</span>
  </div>
</template>
<script>
import PrimaryCheckbox from "@/components/checkbox/Checkbox.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'FilterCheckbox',
  props: {
    option: {
      type: Object,
      required: true,
      default: () => ({})
    },
    value: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  components: {PrimaryCheckbox, FontAwesomeIcon}
};
</script>
<style scoped>
input[type=checkbox] {
  accent-color: red;
}

input[type=checkbox]:checked {
  accent-color: var(--space-dark-blue);
}

>>> .checkbox {
  @apply rounded-sm relative inline-block w-4 h-4 align-middle border border-space-dark-blue cursor-pointer;
}

>>> .checkbox__checkmark {
  @apply bg-space-green rounded-sm absolute w-4 h-4;
}
</style>
