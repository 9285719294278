import I18n from "@/i18n/i18n";
import {
  ROUTE_CONTACT,
  ROUTE_EXERCISES_BUNDLES,
  ROUTE_FAQ,
  ROUTE_MY_EXERCISES_BUNDLES,
  ROUTE_PRESENTATION, ROUTE_PROFILE,
  ROUTE_ROOM_LINK
} from "@/router";
import {ROUTE_DASHBOARD} from "../../router";
import RoomConfigurationService from "@/services/roomConfigurationService";

export const FIRST_SECTION_ITEMS = [
  {
    name: I18n.t('navbar.dashboard'),
    path: ROUTE_DASHBOARD?.path
  },
  {
    name: I18n.t('navbar.my_exercises_bundles'),
    path: ROUTE_MY_EXERCISES_BUNDLES?.path,
  },
  {
    name: I18n.t('navbar.profile'),
    path: ROUTE_PROFILE?.path,
  }
];

export const SECOND_SECTION_ITEMS = [
  {
    name: I18n.t('navbar.join_game'),
    path: ROUTE_ROOM_LINK?.path
  },
  {
    name: I18n.t('navbar.create_game'),
    onClick: () => RoomConfigurationService.createRoom()
  },
  {
    name: I18n.t('navbar.exercises_bundles_community'),
    path: ROUTE_EXERCISES_BUNDLES?.path
  }
];

export const LAST_SECTION_ITEMS = [
  {
    name: I18n.t('navbar.discover'),
    path: ROUTE_PRESENTATION?.path,
  },
  {
    name: I18n.t('navbar.contact'),
    path: ROUTE_CONTACT?.path,
  },
  {
    name: I18n.t('navbar.question'),
    path: ROUTE_FAQ?.path,
  }
];

export const NAVBAR_SECTIONS  = (user) => [
  user && !user.is_guest && user.token ? FIRST_SECTION_ITEMS : [],
  SECOND_SECTION_ITEMS,
  LAST_SECTION_ITEMS
];
