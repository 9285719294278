<template>
  <div>
    <div class="flex flex-col" v-if="!!exerciseToShow">
      <div class="sticky mb-2 top-0 -ml-1 bg-white">
        <GoBackButton
          text-classname="!text-space-dark-blue"
          class="sticky bg-white top-0 text-space-dark-blue"
          :on-click="() => exerciseToShow = null"/>
      </div>
      <ExerciseDetailsModal :exercise="exerciseToShow" />
    </div>
    <div class="bg-white flex flex-col space-y-6" v-if="!exerciseToShow">
      <div class="flex flex-col">
        <span class="text-space-dark-blue font-bold text-2xl">{{$t('bundles.name')}}</span>
        <TextFormInput
          :disabled="true"
          wrapper-classname="w-fit-content shadow-none"
          :value="bundle.name"
        />
      </div>
      <div class="flex flex-col space-y-2">
        <span class="text-space-dark-blue font-bold text-2xl space-x-2">{{$t('exercises.creator')}}</span>
        <UserAvatar user="bundle.creator" v-if="user.avatar" />
        <span class="text-space-dark-blue">{{ bundle.creator.pseudo }}</span>
      </div>
      <span class="text-space-dark-blue font-bold text-2xl">{{$t('bundles.exercises')}}</span>
      <div class="flex flex-col">
        <Table
          :headers="headers"
          table-container-classname="rounded-xl mt-3 bg-white"
          :data="bundle.exercises"
        >
          <template #body>
            <ExercisesTableBody
              :exercises="bundle.exercises"
              :on-row-selected="onRowSelected"
              :actions="actions"
            />
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>
<script>
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import UserAvatar from "@/components/avatar/UserAvatar.vue";
import ExercisesTableBody from "@/components/exercises/ExercisesTableBody.vue";
import {EXERCISES_TABLE_HEADER} from "@/constants/exercices/ExercicesConstants";
import Table from "@/components/Table/Table.vue";
import ExerciseDetailsModal from "@/components/modals/exerciseDetailsModal.vue";
import GoBackButton from "@/components/buttons/GoBackButton.vue";

export default {
  name: 'BundleDetailsModal',
  components:{GoBackButton, ExerciseDetailsModal, Table, ExercisesTableBody, UserAvatar, TextFormInput},
  props: {
    bundle: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      headers: EXERCISES_TABLE_HEADER(true),
      exerciseToShow: null,
      actions: {
        view: {
          label: this.$t('exercises.actions.view'),
          method: this.onRowSelected
        }
      }
    };
  },
  methods: {
    onRowSelected(exercise){
      this.exerciseToShow = exercise;
    }
  }
};
</script>
