/**
 * Creates an object to LHS form params
 * @param filters
 */
export const createQueryParamString = (filters) => {
  if (!filters || !filters.length) {
    return undefined;
  }

  let queryParamString = '';
  for (let filter of filters) {
    if (filter.value) {
      if (Array.isArray(filter.value)) {
        queryParamString = `${queryParamString}${!queryParamString.length ? '' : '&'}${filter.value.map(value => `${filter.field}[${filter.operator}][]=${value}`).join('&')}`;
      } else {
        queryParamString = `${queryParamString}${!queryParamString.length ? '' : '&'}${filter.field}[${filter.operator}]=${filter.value}`;
      }
    }
  }

  return queryParamString.length ? queryParamString : undefined;
};
