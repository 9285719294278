<template>
  <tbody>
    <BundlesRow
      v-for="bundle in bundles"
      :key="bundle.id"
      :bundle="bundle"
      :selected="selectedRows.includes(bundle.id)"
      :on-row-selected="() => onRowSelected(bundle)"
      :user="user"
      :actions="actions"
    />
  </tbody>
</template>
<script>

import BundlesRow from "@/components/bundles/BundlesRow.vue";

export default {
  name: 'BundlesTableBody',
  components: {BundlesRow},
  props: {
    bundles: {
      type: Array,
      required: true,
      default: () => []
    },
    user: {
      type: Object,
      required: true
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    onRowSelected: {
      type: Function,
    },
    hasMultiSelect: {
      type: Boolean
    },
    actions: {
      type: Object,
      default: () => ({
        view: undefined,
        edit: undefined,
        remove: undefined
      })
    }
  }
};
</script>
